import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Button from '../components/ui/Button';
import SocialAuthButton from '../components/auth/SocialAuthButton';
import { ChefHat, Mail, ArrowLeft } from 'lucide-react';
import toast from 'react-hot-toast';

type AuthStep = 'provider' | 'email' | 'details';

export default function AuthPage() {
  const navigate = useNavigate();
  const { signIn, signUp } = useAuth();
  const [step, setStep] = useState<AuthStep>('provider');
  const [isLogin, setIsLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    name: '',
    username: '',
    userType: 'consumer',
    location: '',
    bio: '',
    dietaryPreferences: [] as string[],
    specialties: [] as string[],
    availability: [] as string[],
  });

  const handleEmailAuth = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (isLogin) {
        await signIn(formData.email, formData.password);
        toast.success('Welcome back!');
        navigate('/');
      } else {
        if (formData.username.length < 3) {
          toast.error('Username must be at least 3 characters long');
          return;
        }

        await signUp(formData.email, formData.password, {
          name: formData.name,
          username: formData.username,
          email: formData.email,
          user_type: formData.userType as 'cook' | 'consumer',
          location: formData.location,
          bio: formData.bio,
          dietary_preferences: formData.dietaryPreferences,
          specialties: formData.specialties,
          availability: formData.availability,
        });
        toast.success('Account created successfully!');
        navigate('/');
      }
    } catch (error: any) {
      console.error('Auth error:', error);
      if (error.code === 'auth/invalid-credential') {
        toast.error('Invalid email or password');
      } else {
        toast.error('Authentication error');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleDietaryPreferenceChange = (preference: string) => {
    setFormData(prev => ({
      ...prev,
      dietaryPreferences: prev.dietaryPreferences.includes(preference)
        ? prev.dietaryPreferences.filter(p => p !== preference)
        : [...prev.dietaryPreferences, preference]
    }));
  };

  const renderProviderStep = () => (
    <div className="space-y-4">
      <Button
        onClick={() => setStep('email')}
        variant="outline"
        className="w-full"
      >
        <Mail className="w-5 h-5 mr-2" />
        Continue with Email
      </Button>
    </div>
  );

  const renderEmailStep = () => (
    <form onSubmit={handleEmailAuth} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700">Email</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Password</label>
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
        />
      </div>

      {!isLogin && (
        <Button
          type="button"
          onClick={() => setStep('details')}
          className="w-full"
        >
          Next
        </Button>
      )}

      {isLogin && (
        <Button
          type="submit"
          className="w-full"
          isLoading={loading}
        >
          Sign in
        </Button>
      )}
    </form>
  );

  const renderDetailsStep = () => (
    <form onSubmit={handleEmailAuth} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700">Full Name</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Username</label>
        <input
          type="text"
          name="username"
          value={formData.username}
          onChange={handleChange}
          required
          minLength={3}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">I want to</label>
        <select
          name="userType"
          value={formData.userType}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
        >
          <option value="consumer">Order Food</option>
          <option value="cook">Sell Food</option>
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Location</label>
        <input
          type="text"
          name="location"
          value={formData.location}
          onChange={handleChange}
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Bio</label>
        <textarea
          name="bio"
          value={formData.bio}
          onChange={handleChange}
          rows={3}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
          placeholder="Tell us about yourself..."
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Dietary Preferences
        </label>
        <div className="grid grid-cols-2 gap-2">
          {['Vegetarian', 'Vegan', 'Gluten-Free', 'Halal', 'Kosher', 'Dairy-Free'].map(preference => (
            <label key={preference} className="flex items-center">
              <input
                type="checkbox"
                checked={formData.dietaryPreferences.includes(preference)}
                onChange={() => handleDietaryPreferenceChange(preference)}
                className="rounded border-gray-300 text-orange-500 focus:ring-orange-500"
              />
              <span className="ml-2 text-sm text-gray-600">{preference}</span>
            </label>
          ))}
        </div>
      </div>

      {formData.userType === 'cook' && (
        <div>
          <label className="block text-sm font-medium text-gray-700">Specialties</label>
          <input
            type="text"
            name="specialties"
            value={formData.specialties.join(', ')}
            onChange={(e) => setFormData(prev => ({
              ...prev,
              specialties: e.target.value.split(',').map(s => s.trim())
            }))}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
            placeholder="Italian, Asian, Desserts..."
          />
        </div>
      )}

      <Button
        type="submit"
        className="w-full"
        isLoading={loading}
      >
        Create Account
      </Button>
    </form>
  );

  return (
    <div className="min-h-screen flex items-center justify-center px-4 py-12 bg-gray-50">
      <div className="max-w-md w-full space-y-8">
        <div className="text-center">
          <ChefHat className="mx-auto h-12 w-12 text-orange-500" />
          <h2 className="mt-6 text-3xl font-bold text-gray-900">
            {step === 'provider' ? 'Welcome to BlablaFood' : isLogin ? 'Welcome back' : 'Create your account'}
          </h2>
          {step !== 'provider' && (
            <p className="mt-2 text-sm text-gray-600">
              {isLogin ? "Don't have an account? " : 'Already have an account? '}
              <button
                onClick={() => {
                  setIsLogin(!isLogin);
                  setStep('provider');
                }}
                className="font-medium text-orange-500 hover:text-orange-400"
              >
                {isLogin ? 'Sign up' : 'Sign in'}
              </button>
            </p>
          )}
        </div>

        {step !== 'provider' && (
          <button
            onClick={() => setStep('provider')}
            className="flex items-center text-sm text-gray-500 hover:text-gray-700"
          >
            <ArrowLeft className="w-4 h-4 mr-1" />
            Back
          </button>
        )}

        <div className="mt-8">
          {step === 'provider' && renderProviderStep()}
          {step === 'email' && renderEmailStep()}
          {step === 'details' && renderDetailsStep()}
        </div>
      </div>
    </div>
  );
}