import { useState, useEffect } from 'react';
import { Download } from 'lucide-react';
import Button from './Button';

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
}

export default function InstallPWA() {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    const handler = (e: Event) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e as BeforeInstallPromptEvent);
    };

    window.addEventListener('beforeinstallprompt', handler);

    return () => window.removeEventListener('beforeinstallprompt', handler);
  }, []);

  const handleInstallClick = async () => {
    if (!promptInstall) return;

    try {
      await promptInstall.prompt();
      const { outcome } = await promptInstall.userChoice;
      
      if (outcome === 'accepted') {
        setSupportsPWA(false);
      }
    } catch (error) {
      console.error('Error installing PWA:', error);
    }
  };

  if (!supportsPWA) return null;

  return (
    <Button
      onClick={handleInstallClick}
      variant="outline"
      size="sm"
      className="fixed bottom-4 right-4 shadow-lg z-50"
    >
      <Download className="w-4 h-4 mr-2" />
      Installer l'application
    </Button>
  );
}