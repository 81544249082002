import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import Button from '../components/ui/Button';
import { Clock, Package } from 'lucide-react';
import type { Order, OrderItem, Meal } from '../types/database';

interface OrderWithDetails extends Order {
  items: (OrderItem & { meal: Meal })[];
}

export default function OrdersPage() {
  const { user } = useAuth();
  const [orders, setOrders] = useState<OrderWithDetails[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadOrders = async () => {
      if (!user) return;

      try {
        const ordersRef = collection(db, 'orders');
        const q = query(
          ordersRef,
          where('consumer_id', '==', user.user_id),
          orderBy('created_at', 'desc')
        );
        
        const querySnapshot = await getDocs(q);
        const ordersData = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          order_id: doc.id,
        })) as OrderWithDetails[];

        // Load order items and meal details for each order
        // Implementation needed

        setOrders(ordersData);
      } catch (error) {
        console.error('Error loading orders:', error);
      } finally {
        setLoading(false);
      }
    };

    loadOrders();
  }, [user]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-orange-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Your Orders</h1>

      {orders.length === 0 ? (
        <div className="text-center py-12">
          <Package className="w-16 h-16 text-gray-400 mx-auto mb-4" />
          <p className="text-gray-600 mb-4">You haven't placed any orders yet</p>
          <Button onClick={() => window.location.href = '/meals'}>Browse Meals</Button>
        </div>
      ) : (
        <div className="space-y-6">
          {orders.map((order) => (
            <div key={order.order_id} className="bg-white rounded-lg shadow-sm overflow-hidden">
              <div className="p-6">
                <div className="flex items-center justify-between mb-4">
                  <div>
                    <p className="text-sm text-gray-500">Order #{order.order_id.slice(-8)}</p>
                    <p className="text-sm text-gray-500">
                      <Clock className="w-4 h-4 inline mr-1" />
                      {new Date(order.created_at).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <span className="px-3 py-1 rounded-full text-sm font-medium capitalize" style={{
                      backgroundColor: order.order_status === 'delivered' ? 'rgb(220 252 231)' : 'rgb(254 243 199)',
                      color: order.order_status === 'delivered' ? 'rgb(22 163 74)' : 'rgb(180 83 9)'
                    }}>
                      {order.order_status}
                    </span>
                  </div>
                </div>

                <div className="border-t border-gray-200 pt-4">
                  {order.items?.map((item) => (
                    <div key={item.order_item_id} className="flex items-center gap-4 mb-4">
                      <img
                        src={item.meal.image_url || 'https://images.unsplash.com/photo-1546069901-ba9599a7e63c'}
                        alt={item.meal.name}
                        className="w-16 h-16 object-cover rounded-lg"
                      />
                      <div>
                        <p className="font-medium text-gray-900">{item.meal.name}</p>
                        <p className="text-sm text-gray-600">
                          {item.quantity} x ${item.price.toFixed(2)}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="border-t border-gray-200 pt-4 mt-4">
                  <div className="flex justify-between text-gray-900">
                    <span className="font-medium">Total</span>
                    <span className="font-bold">${order.total_amount.toFixed(2)}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}