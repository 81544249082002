import Button from '../ui/Button';

interface CartSummaryProps {
  subtotal: number;
  deliveryOption: 'pickup' | 'delivery';
  deliveryAddress: string;
  onDeliveryOptionChange: (option: 'pickup' | 'delivery') => void;
  onDeliveryAddressChange: (address: string) => void;
  onCheckout: () => void;
  isProcessing: boolean;
}

export default function CartSummary({
  subtotal,
  deliveryOption,
  deliveryAddress,
  onDeliveryOptionChange,
  onDeliveryAddressChange,
  onCheckout,
  isProcessing
}: CartSummaryProps) {
  const deliveryFee = deliveryOption === 'delivery' ? 5 : 0;
  const total = subtotal + deliveryFee;

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h2 className="text-xl font-semibold mb-4">Résumé de la commande</h2>
      
      <div className="space-y-4">
        <div className="flex justify-between">
          <span>Sous-total</span>
          <span>{subtotal.toFixed(2)}€</span>
        </div>

        <div className="border-t pt-4">
          <h3 className="font-medium mb-2">Option de livraison</h3>
          <div className="space-y-2">
            <label className="flex items-center">
              <input
                type="radio"
                value="pickup"
                checked={deliveryOption === 'pickup'}
                onChange={(e) => onDeliveryOptionChange(e.target.value as 'pickup')}
                className="mr-2"
              />
              Retrait (Gratuit)
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                value="delivery"
                checked={deliveryOption === 'delivery'}
                onChange={(e) => onDeliveryOptionChange(e.target.value as 'delivery')}
                className="mr-2"
              />
              Livraison (5€)
            </label>
          </div>

          {deliveryOption === 'delivery' && (
            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Adresse de livraison
              </label>
              <textarea
                value={deliveryAddress}
                onChange={(e) => onDeliveryAddressChange(e.target.value)}
                required
                rows={3}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500"
              />
            </div>
          )}
        </div>

        {deliveryOption === 'delivery' && (
          <div className="flex justify-between">
            <span>Frais de livraison</span>
            <span>{deliveryFee.toFixed(2)}€</span>
          </div>
        )}

        <div className="border-t pt-4">
          <div className="flex justify-between text-lg font-semibold">
            <span>Total</span>
            <span>{total.toFixed(2)}€</span>
          </div>
        </div>

        <Button
          onClick={onCheckout}
          className="w-full"
          isLoading={isProcessing}
        >
          Procéder au paiement
        </Button>
      </div>
    </div>
  );
}