import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { TranslationProvider } from './contexts/TranslationContext';
import { AuthProvider } from './contexts/AuthContext';
import { CartProvider } from './contexts/CartContext';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import InstallPWA from './components/ui/InstallPWA';
import HomePage from './pages/HomePage';
import MealsPage from './pages/MealsPage';
import MealDetailsPage from './pages/MealDetailsPage';
import CreateMealPage from './pages/CreateMealPage';
import ProfilePage from './pages/ProfilePage';
import CartPage from './pages/CartPage';
import OrdersPage from './pages/OrdersPage';
import AuthPage from './pages/AuthPage';
import CookDashboardPage from './pages/CookDashboardPage';
import ContactPage from './pages/ContactPage';
import PrivacyPage from './pages/PrivacyPage';
import FaqPage from './pages/FaqPage';
import AboutPage from './pages/AboutPage';
import ProtectedRoute from './components/auth/ProtectedRoute';
import CookRoute from './components/auth/CookRoute';

function App() {
  return (
    <TranslationProvider>
      <AuthProvider>
        <CartProvider>
          <Router>
            <div className="min-h-screen flex flex-col bg-gray-50">
              <Header />
              <main className="flex-grow pt-16">
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/meals" element={<MealsPage />} />
                  <Route path="/meals/:mealId" element={<MealDetailsPage />} />
                  <Route path="/auth" element={<AuthPage />} />
                  <Route path="/about" element={<AboutPage />} />
                  <Route path="/contact" element={<ContactPage />} />
                  <Route path="/privacy" element={<PrivacyPage />} />
                  <Route path="/faq" element={<FaqPage />} />
                  
                  {/* Protected Routes */}
                  <Route element={<ProtectedRoute />}>
                    <Route path="/profile" element={<ProfilePage />} />
                    <Route path="/cart" element={<CartPage />} />
                    <Route path="/orders" element={<OrdersPage />} />
                    
                    {/* Cook-only routes */}
                    <Route element={<CookRoute />}>
                      <Route path="/cook/dashboard" element={<CookDashboardPage />} />
                      <Route path="/create-meal" element={<CreateMealPage />} />
                    </Route>
                  </Route>
                </Routes>
              </main>
              <Footer />
              <InstallPWA />
              <Toaster 
                position="top-center"
                toastOptions={{
                  duration: 3000,
                  style: {
                    background: '#363636',
                    color: '#fff',
                  },
                }}
              />
            </div>
          </Router>
        </CartProvider>
      </AuthProvider>
    </TranslationProvider>
  );
}

export default App;